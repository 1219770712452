import React, { useEffect, useRef, useState } from "react";
import "./App.css";

function App() {
  const [code, setCode] = useState("");
  const [invoice, setInvoice] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const urlStr = window.location.href;
    const url = new URL(urlStr);
    const t_code = url.searchParams.get("t_code");
    const t_invoice = url.searchParams.get("t_invoice");

    if (t_code && t_invoice) {
      setCode(t_code);
      setInvoice(t_invoice);
    }
  }, []);

  useEffect(() => {
    if (code && invoice && formRef.current) {
      formRef.current.submit();
    }
  }, [code, invoice]);

  return (
    <div className="App">
      <form
        ref={formRef}
        action="https://info.sweettracker.co.kr/tracking/5"
        method="post"
        hidden
      >
        <div className="form-group">
          <label htmlFor="t_key">API key</label>
          <input
            type="text"
            className="form-control"
            id="t_key"
            name="t_key"
            value={"Ykif47Lt5KEkXnTKi1rTiw"}
          />
        </div>
        <div className="form-group">
          <label htmlFor="t_code">택배사 코드</label>
          <input
            type="text"
            className="form-control"
            name="t_code"
            id="t_code"
            value={code}
          />
        </div>
        <div className="form-group">
          <label htmlFor="t_invoice">운송장 번호</label>
          <input
            type="text"
            className="form-control"
            name="t_invoice"
            id="t_invoice"
            value={invoice}
          />
        </div>
        <button type="submit" className="btn btn-default">
          조회하기
        </button>
      </form>
    </div>
  );
}

export default App;
